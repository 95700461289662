import styled from 'styled-components'

const Heading4 = styled.h3.attrs(props => ({
    color: props.color || 'black'
  }))`
  color: ${props => props.color};
  font-size: 1.6rem;
  line-height: 3rem;
  font-weight: 600;
  letter-spacing: -0.1rem;
  margin-bottom: 20px;
  `
  export default Heading4;
