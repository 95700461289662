import React from "react"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/Seo/Seo"
import PageHeader from '../../components/Layout/PageHeader/PageHeader'
import SectionPageMargin from '../../components/Layout/Section/SectionPageMargin'
import Heading2 from '../../components/Type/Heading2'
import Heading4 from '../../components/Type/Heading4'
import Paragraph1 from '../../components/Type/Paragraph1'
import ReadyToGetStarted from '../../components/ReadyToGetStarted/ReadyToGetStarted'

const ArticlePage = ({ location }) =>
(
  <Layout>

    <SEO title="Toyota Landcruiser buyers guide"
      description="Buying a Toyota Landcruiser? Guide for Landcruiser prices, specs and where to buy."
      location={location}
    />

    <PageHeader
      title="Toyota Landcruiser buyers guide"
      text="Thanks to its robust construction, good reliability record, on-road comfort and off-road ability, the Toyota Land Cruiser 200-series mk2 has been one of Australia’s most popular SUVs since its launch in 2012."
    />

    <SectionPageMargin>

      <Heading2>Buying a Toyota Landcruiser? Guide for Landcruiser prices, specs and where to buy.</Heading2>

      <Paragraph1>Last update: 28th September 2021</Paragraph1>

      <Heading4>Market Analysis</Heading4>
      <Paragraph1>
      Beloved of outback adventurers and urban commuters alike, the Toyota Land Cruiser 200-series mk2 has traditionally enjoyed excellent residual values: age and high kilometres impact used prices far less than with most rivals.
      </Paragraph1>

      <Paragraph1>
      Values of newer models are currently being buoyed by the imminent arrival of the 300-series Land Cruiser. In normal circumstances when a car faces replacement there are discounts on new showroom stock and a dip in used prices: in this instance Toyota has announced that the ever-popular 4.5-litre twin-turbo V8 diesel engine (also known as the VDJ200R model) is to die along with the 200-series, and this has triggered a buying frenzy on the new and used markets, bolstering prices.
      </Paragraph1>

      <Paragraph1>
      The V8 diesel has a fine reputation for towing and has an excellent driving range – up to 1,000km: it’s expected that the 300-series will feature a hybrid powertrain, and that will need to prove itself in Australia’s unique conditions before being trusted. Hence a rush to get hold of a newish Land Cruiser 200-series mk2 before it’s too late.
      </Paragraph1>

      <Paragraph1>
      Even before the revelation about the forthcoming Land Cruiser 300-series’ under-bonnet transformation, values of the luxury four-wheel drive Toyota were robust. So popular are late-model 200-series twin-turbo V8 diesel Land Cruisers that what’s on the odometer counts for little. Based on Summon’s data, collected from the past 12 months of private and dealer sale ads in Australia, the average price for the range-topping 2019 Sahara derivative with less than 20,000km on the clock was $129,381: examples with 60,000km+ showing on the odometer averaged $127,416.
      </Paragraph1>

      <Paragraph1>
      Same story with the next version down, the VX. In 2019 the average price for a 50,000km example was $113,175 – double the odometer digits and you’d still be spending on average $99,442. The gap’s even tighter with the GXL model (entry trim level for non-utilitarian Land Cruisers), where in 2019 a 20,000km example cost $100,804 on average, a mere $809 more than one with 60,000km on the odometer.
      </Paragraph1>

      <Paragraph1>
      The Toyota Land Cruiser 200-series mk2 was also available with a 4.6-litre V8 naturally aspirated petrol engine (the URJ202R model): it was a good motor but unpopular, and Toyota pensioned it off at the end of 2019. Summon doesn’t have much sales data on the 4.6-litre V8, but in 2010 its used values lagged around $11,500 behind a comparable V8 diesel derivative.
      </Paragraph1>

      <Paragraph1>
      The upshot of all this is that you’re unlikely to find a bargain Toyota Land Cruiser 200-series mk2 – even a 200-series mk1 Sahara with 250,000km on the clock is being advertised for around $55,000. On the other hand, if you look after your Land Cruiser well, then you shouldn’t lose much money when it’s time to sell.
      </Paragraph1>

      <Paragraph1>
      For an even more in-depth examination of the Land Cruiser 200-series mk2 market, check out the tables and graphs below.
      </Paragraph1>

      <Heading4>What is it?</Heading4>
      <Paragraph1>
      The Toyota Land Cruiser 200-series mk2 is a large – huge, even – luxury SUV with a sophisticated permanent full-time dual-range four-wheel drive system, six-speed automatic gearbox, and the choice of either a 227kW petrol V8 engine, or a 200kW twin-turbo V8 diesel: most buyers chose the latter when new. The 200-series mk2 was launched in 2012 and discontinued in Australia in spring 2021: the slow-selling petrol model was phased out in late 2019.
      </Paragraph1>

      <Paragraph1>
      There are three trim grades – GXL, VX and Sahara. The GXL is turbodiesel only and features alloy wheels, dual-zone climate control, satnav, DAB radio, LED headlights and keyless start/entry. Next in the pecking order is the VX, with four-zone climate control, automatic headlights and wipers, sunroof and an eight-speaker sound system. To that the Sahara adds heated and ventilated front seats, active cruise control, a cool box, rear seat DVD screens, electric tailgate, and lane departure and rear cross-traffic alerts.
      </Paragraph1>

      <Paragraph1>
      Both the VX and Sahara feature as standard Toyota’s Kinetic Dynamic Suspension System, or KDSS. This automatic suspension aide reduces body roll through corners on the road, but also allows for greater axle articulation when off-roading. If you’re looking at a GXL, check if KDSS was fitted as an option.
      </Paragraph1>

      <Paragraph1>
      Only the GXL and VX petrol models have eight seats: the VX diesel and both Sahara models have seven seats to avoid exceeding gross vehicle mass – GVM – restrictions. Talking of GVM, if you’re planning to tow, beware… The Land Cruiser 200-series mk2’s GVM is only 3,350kg, leaving you just 610kg for occupants, fuel, provisions and accessories, including caravans and boats. There are ways to upgrade your Land Cruiser 200-series mk2’s GVM, so either investigate online or talk to your local Toyota dealer.
      </Paragraph1>

      <Heading4>What to look out for</Heading4>
      <Paragraph1>
      <strong>Engine:</strong> Neither the petrol or diesel V8 engines have many reported problems, but there is one major one, known as ‘dusting’. A poorly designed air filter box can allow dust ingress to the engine that ultimately can lead to its expensive destruction. It’s not much of an issue for town-bound Land Cruisers, but if you plan on heading into the bush, consider a snorkel air intake.
      </Paragraph1>

      <Paragraph1>
      <strong>Electrics:</strong> Important chunks of the V8 diesel’s electrical package are mounted low in the engine bay: this impacts the car’s wading depth if you’re considering serious off-roading or tackling floodwater.
      </Paragraph1>

      <Paragraph1>
      <strong>Suspension:</strong> No major horror stories reported with the standard suspension. Plenty of original owners have uprated the suspension for outback adventures, so you should have reasonable choice if you’re planning to follow in their tracks.
      </Paragraph1>

      <Paragraph1>
      <strong>Bodywork:</strong> Check for signs of off-road mishaps and supermarket car park damage. Toyota’s metallic and pearlescent paints can be hard to match if the car has faded in the harsh summer sun.
      </Paragraph1>


    </SectionPageMargin>

    <ReadyToGetStarted />

  </Layout>

)

export default ArticlePage
